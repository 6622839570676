.createOrder-cont {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    
    &__subTitileWrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
        padding-right: 8px;
    }

    &__wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &__text {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }

    &__divider {
        margin: 0;
    }
}

@media screen and (max-width: 767px) {
    .createOrder-cont {
        width: 100%;
        gap: 12px;

        &__wrapper {
            gap: 12px;
        }
    }
}