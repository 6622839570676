.deskNumber-cont {
    width: 100%;
    max-width: 350px !important;
    border-radius: 8px;

    &__form {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &__displayNone {
        display: none;
    }

    &__btn {
        width: 100%;
        margin-left: 0px !important;
    }

    .ant-form-item {
        margin: 0px;
    }
}

@media screen and (max-width: 350px) {
    .deskNumber-cont {
        max-width: 250px !important;
    }
}