.patientList {
    width: 100%;

    &__item {
        width: 100%;
        border-radius: 8px;
        margin-bottom: 8px;
        border-bottom: none !important;

        &__meta {
            width: 100%;
        }

        &__queWrapper {
            width: 100%;
            display: flex;
            gap: 8px;
            align-items: center;
        }

    }


    &__wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 900px) {
    .patientList {
        &__item {
            &__meta {
                &__address {
                    width: 100%;
                    max-width: 150px !important;
                }
            }
        }
    }
}