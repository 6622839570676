@keyframes glowEffect {
    0% {
        box-shadow: 0 0 5px var(--primary-color);
        ;
    }

    50% {
        box-shadow: 0 0 15px var(--primary-color);
        ;
    }

    100% {
        box-shadow: 0 0 5px var(--primary-color);
        ;
    }
}

// @keyframes movingBorder {
//     0% {
//         border-top: 4px solid #61BC18;
//     }
//     33% {
//         border-right: 4px solid rgba(173,62,240,255); // Fully purple
//     }
//     66% {
//         border-bottom: 4px solid #61BC18; // Back to green
//     }
//     100% {
//         border-left: 4px solid rgba(173,62,240,255);
//     }
// }

.queueProcessing-cont {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 8px;
    gap: 16px;
    order: 3;

    &__text {
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px;
    }

    &__cards {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        gap: 16px;

        &__card {
            width: 100%;
            display: flex;
            flex-direction: column;
            // gap: 8px;
            border-radius: 8px;
            align-items: center;
            justify-content: center;
            padding: 8px;

            &__paymentDesk {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    }

    &__ticketNumber {
        font-size: 37px;
        font-style: normal;
        font-weight: 600;
        line-height: 46px;
        text-align: center;
    }

    &__number {
        font-size: 47px;
        font-style: normal;
        font-weight: 600;
        line-height: 56px;
        text-align: center;
    }

    &__highlight {
        animation: glowEffect 0.8s ease-in-out 5;
        animation-fill-mode: forwards;
    }
}

@media screen and (max-width: 1240px) {
    .queueProcessing-cont {
        width: 100%;

        &__ticketNumber {
            font-size: 32px;
        }

        &__number {
            font-size: 42px;
        }
    }
}

@media screen and (max-width: 767px) {
    .queueProcessing-cont {
        width: 100%;
        order: 1;

        &__ticketNumber {
            font-size: 26px;
            line-height: 36px;
        }

        &__number {
            font-size: 32px;
            line-height: 46px;
        }

        &__cards {
            gap: 8px;
        }
    }
}

@media screen and (max-width: 400px) {
    .queueProcessing-cont {
        &__ticketNumber {
            font-size: 20px;
            line-height: 26px;
        }

        &__number {
            font-size: 26px;
            line-height: 36px;
        }
    }
}