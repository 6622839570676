.patientCardCont {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 8px;
        align-items: flex-start;
        padding-right: 8px;
    }

    &__text {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }
}

@media screen and (max-width: 767px) {
    .patientCardCont {
        gap: 12px;
    }
}