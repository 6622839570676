.customerCard {
    width: 100%;

    &__descriptionCont {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: calc(100% - 46px - 12px);

        &__deatilCont {
            display: flex;
            flex-direction: column;
            width: 100%;

            &__text {
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
            }

            &__textWrapper {
                display: flex;
                gap: 2px;
                justify-content: center;
                flex-direction: column;
            }
        }
    }

    .ant-card-body::after {
        display: none;
    }

    .ant-card-body::before {
        display: none;
    }
}