.cashierCard-cont {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__card {
        width: 100%;

        &__descriptionCont {
            display: flex;
            flex-direction: column;
            gap: 8px;
            width: calc(100% - 46px - 12px);

            &__productCont {
                display: flex;
                flex-direction: column;
                grid-area: 6px;

                &__text {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 20px;
                }

                &__textWrapper {
                    display: flex;
                    gap: 4px;
                    justify-content: space-between;
                    align-items: center;
                }
            }
        }
    }


    .ant-card-body::after {
        display: none;
    }

    .ant-card-body::before {
        display: none;
    }
}