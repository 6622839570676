.cardTicket {
    width: 100%;

    &__body {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;
        justify-content: center;

        &__wrapper {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 8px;

            &__text {
                font-size: 22px;
                font-style: normal;
                font-weight: 600;
                line-height: 26px;
            }

            &__number {
                font-size: 32px;
                font-style: normal;
                font-weight: 600;
                line-height: 46px;
            }
        }
    }
}

@media screen and (max-width: 1240px) {
    .cardTicket {
        &__body {
            &__wrapper {
                &__number {
                    font-size: 26px;
                    line-height: 36px;
                }
            }
        }
    }
}

@media screen and (max-width: 400px) {
    .cardTicket {
        &__body {
            &__wrapper {
                &__number {
                    font-size: 26px;
                    line-height: 36px;
                }

                &__text {
                    font-size: 20px;
                    line-height: 26px;
                }
            }
        }
    }
}