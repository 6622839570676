.queueViewer-cont {
    width: 100%;
    padding: 32px 120px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    height: calc(100% - 80px); // 100% - header-height
    min-height: 500px;

    &__text {
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px;
    }

    &__screen {
        width: 100%;
        height: 100%;
        display: flex;
        gap: 16px;
    }
}

@media screen and (max-width: 1240px) {
    .queueViewer-cont {
        padding: 32px 80px;
    }
}

@media screen and (max-width: 1080px) {
    .queueViewer-cont {
        padding: 32px 40px;
    }
}

@media screen and (max-width: 900px) {
    .queueViewer-cont {
        padding: 16px;
        height: calc(100% - 56px); //100% - header height
        gap: 16px;
    }
}

@media screen and (max-width: 767px) {
    .queueViewer-cont {
        &__screen {
            flex-direction: column;
        }
    }
}